<template>
	<MainColumn>
		<div class="chef" v-if="author && last_posts">
			<div class="header-chef">
				<v-row>
					<v-col cols="12" sm="3">
						<div class="chef-image-holder">
							<div
								class="chef-image"
								:style="'background-image:url(' + author.qzr_image.thumbnail + ')'"
							></div>
						</div>
					</v-col>
					<v-col cols="12" sm="8" class="details">
						<h2 @click="goTo('Chef', { slug: author.slug })" class="color-aili-pink">
							{{ author.first_name + ' ' + author.last_name }}
						</h2>
						<p class="color-black font-size-15 bio" v-html="author.description_tiny[0]" />

						<Button
							color="aili-pink"
							@click="goTo('Chef', { slug: author.slug })"
							value="Scopri le ricette"
						/>
					</v-col>
				</v-row>
			</div>
			<div class="last-recipes-by-chef" v-if="last_posts">
				<div class="buttons">
					<div @click="prevPage"><Arrow type="left" color="#f72585" /></div>
					<div @click="nextPage">
						<Arrow @click="prevPage" type="right" color="#f72585" />
					</div>
				</div>
				<div class="last-recipes-by-chef-swiper">
					<swiper @slideChange="onSlideChange" ref="swiperRecipeCategories" :options="swiperOption">
						<swiper-slide v-for="(card, i) in last_posts" :key="i">
							<div class="link card">
								<CardSingleRecipe
									:slug="card.slug"
									:image="card.featured_media_data.medium"
									:title="card.title.rendered"
									:author="card.author_data"
									:preview_data="card.preview_data"
									:refresh="true"
									:categories="card['recipe-category_data']"
								/>
							</div>
						</swiper-slide>
						<div class="swiper-pagination" slot="pagination"></div>
					</swiper>
				</div>
			</div>
		</div>
	</MainColumn>
</template>
<script>
	import Arrow from '@/components/ui/Arrow.vue';
	import CardSingleRecipe from '@/components/ui/CardSingleRecipe.vue';

	export default {
		props: ['author', 'color'],
		components: {
			CardSingleRecipe,
			Arrow
		},
		data() {
			return {
				last_posts: false,
				swiperOption: {
					slidesPerView: 1,
					//spaceBetween: 25,
					centeredSlides: false,
					pagination: {
						el: '.swiper-pagination',
						clickable: true
					},
					breakpoints: {
						0: {
							slidesPerView: 1
						},
						640: {
							slidesPerView: 2
						},
						1024: {
							slidesPerView: 3
							//spaceBetween: 20,
						}
					}
				}
			};
		},
		async mounted() {
			this.last_posts = await this.getLastPosts();
		},
		computed: {
			swiper() {
				return this.$refs.swiperRecipeCategories.$swiper;
			}
		},
		methods: {
			async getLastPosts() {
				let last_posts = await this.$api.get('wp/v2/recipe', {
					params: {
						'author[]': this.author.id,
						per_page: 3
					}
				});

				return last_posts.data;
			},
			onSlideChange() {
				this.currentIndex = this.swiper.activeIndex;
			},
			changeUrl(url) {
				window.location.href = url;
			},
			onPaginationClick(index) {
				this.swiper.slideTo(index);
			},
			nextPage() {
				this.swiper.slideNext();
			},
			prevPage() {
				this.swiper.slidePrev();
			}
		}
	};
</script>
<style lang="scss" scoped>
.chef {
  padding: $spacing-1 $spacing-0;

  @media (max-width: $tablet-m) {
    padding: $spacing-1 $spacing-0;
  }

  @media (max-width: $mobile-m) {
    padding: $spacing-1 $spacing-0/2;
  }

  .header-chef {
    margin-bottom: $spacing-1;
    @media (max-width: $tablet-l) {
      display: inline-flex;
      flex-wrap: wrap;
      width: 100%;
    }
  }

  .chef-image-holder {
    padding-right: $spacing-0;
    .chef-image {
      width: 100%;
      padding-top: 100%;
      margin-bottom: $spacing-0;
      background-size: cover;
      border-radius: 100%;

      @media (max-width: $tablet-s - 1) {
        width: 200px;
        padding-top: 200px;
        margin: 0 auto;
      }
    }
  }
  .details {
    padding-left: 20px;
    h2 {
      margin-bottom: $spacing-0;
      cursor: pointer;
    }
  }
}

.card {
  box-sizing: border-box;
  width: 100%;
  min-height: 400px;
  padding: 0 $spacing-0/3 * 2;
  margin-bottom: 40px;
  text-decoration: none;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  cursor: pointer;

  .image-card {
    width: 100%;
    height: 200px;
    padding: 0;
    background-position: center;
    background-size: cover;
  }
  .content-card {
    position: relative;
    min-height: 150px;
    padding: $spacing-0;
  }
  h3 {
    margin: 0;
    margin-bottom: $spacing-0;
  }
  h4 {
    position: absolute;
    bottom: $spacing-0;
  }
}

.link {
  display: inline-block;
  text-decoration: none;
  * {
    text-align: left;
  }
  @media (max-width: $tablet-l) {
    text-align: center;
  }
}
.buttons {
  position: absolute;
  @media (max-width: $tablet-m) {
    display: none;
  }
}
.pagination {
  display: none;
  @media (max-width: $tablet-m) {
    display: block;
  }
}
.swiper-wrapper {
  margin-right: $spacing-0 !important;
  margin-bottom: $spacing-1 !important;
}
</style>
<style lang="scss">
	.last-recipes-by-chef-swiper {
		width: calc(100% - 60px);
		margin-left: 60px;

		@media (max-width: $tablet-m) {
			margin: auto;
			width: 100%;
		}
		//Class inside swiper need to be declared outside of scoped
		.swiper-pagination-bullet-active {
			background-color: $aili-pink;
		}
	}
</style>
